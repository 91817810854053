import React from 'react'
import { Layout, Stack,  Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import Seo from '@widgets/Seo'
import { useBlogTags, useBlogCategories } from '@helpers-blog'
import { useStaticQuery, graphql } from 'gatsby'
import dedupe from 'dedupe'



const AllPosts = ({ data: { paginatedPosts = { }, posts }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props

    const { allArticleCategory, allArticleTag } = useStaticQuery(tagscategoriesQuery)
    const categories = allArticleCategory.nodes
    ? dedupe(allArticleCategory.nodes, node => node.slug)
    : null

    const tags = allArticleTag.nodes || []

  return (
    <Layout {...props}>
      <Seo title='Blog Posts' />
      <Divider />
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          <CardList
            variant={['horizontal']}
            title='Blog Posts'
            nodes={paginatedPosts.nodes}
            columns={[1, 2]}
            omitMedia
          />
        </Main>
        <Divider space={3}/>
        <Sidebar >
          <Divider/>
          <Divider/>
          <Categories categories={categories} />
          <Divider />
          <Tags tags={tags} />
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={'/blogs'} />
      </PreFooter>
      <Divider space={5} />
      <Stack>
        <Main sx={{ zIndex: 1 }}>
          {services.mailchimp && <NewsletterExpanded simple />}
        </Main>
      </Stack>
    </Layout>
  )
}

export default AllPosts

const tagscategoriesQuery = graphql`
  query allArticleCategoryQueryAllPosts {
        allArticleCategory {
            nodes {
                ...ArticleCategory
            }
        }
        allArticleTag {
            nodes {
                ...ArticleTag
            }
        }
    }
`
